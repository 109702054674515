import { Injectable } from '@angular/core';

interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
  { name: 'jquery', src: '../../assets/js/jquery.min.js' },
  { name: 'smooth-scroll', src: '../../assets/js/SmoothScroll.js' },
  { name: 'modernizr', src: '../../assets/js/modernizr.js' },
  { name: 'device', src: '../../assets/js/device.min.js' },
  { name: 'yt-player', src: '../../assets/js/jquery.mb.YTPlayer.js' },
  { name: 'flex-slider', src: '../../assets/js/jquery.flexslider-min.js' },
  { name: 'parallax', src: '../../assets/js/jquery.parallax.min.js' },
  { name: 'scroll-to', src: '../../assets/js/jquery.scrollTo.min.js' },
  { name: 'waypoints', src: '../../assets/js/waypoints.min.js' },
  { name: 'magnific-popup', src: '../../assets/js/jquery.magnific-popup.min.js' },
  { name: 'slider', src: '../../assets/js/slider.js' },
  { name: 'custom', src: '../../assets/js/jquery.custom.js' },
  { name: '404', src: '../../assets/js/jquery.404.js' }
];

declare var document: any;

@Injectable()
export class DynamicScriptLoaderService {

  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  public load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  private loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {  //IE
            script.onreadystatechange = () => {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    this.scripts[name].loaded = true;
                    resolve({script: name, loaded: true, status: 'Loaded'});
                }
            };
        } else {  //Others
            script.onload = () => {
                this.scripts[name].loaded = true;
                resolve({script: name, loaded: true, status: 'Loaded'});
            };
        }
        script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
}
}
