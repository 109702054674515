import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Order } from '../models/Order';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Injectable()
export class OrderService {

  ordersCollection: AngularFirestoreCollection<Order>;
  orderDocument: AngularFirestoreDocument;
  orders: Observable<Order[]>;
  order: Observable<Order>;

  constructor(private afs: AngularFirestore) { 
    this.ordersCollection = afs.collection<Order>('orders');
    this.orders = this.ordersCollection.valueChanges();
  }

  addOrder(order: Order) {
    this.ordersCollection.add(order);
  }

  getOrder(id: string): Observable<Order> {
    this.orderDocument = this.afs.doc<Order>(`orders/${id}`);
    this.order = this.orderDocument.valueChanges();
    return this.order;
  }

  getOrdersById(id: string): Observable<Order[]> {
    this.orders = this.afs.collection<Order>('orders', ref => ref.where('id', '==', id )).snapshotChanges().pipe(
      map(actions => {
      return actions.map(a => {
        const data = a.payload.doc.data() as Order;
        const id = a.payload.doc.id;
        return {id, ...data};
      })
    }));
    return this.orders;
  }
}
